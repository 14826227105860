<template>
  <b-card no-body>
    <b-card-header>
      <div class="w-100 d-flex justify-content-between align-items-center">
        <b-card-title>
          Filters
        </b-card-title>
      </div>
    </b-card-header>

    <div class="filters-panel" v-if="showCourseFilter">
      <div class="filters">
        <div class="block">
          <label for="date-to-input">Choose from course date</label>
          <b-input-group>
            <b-form-input
                id="date-to-input"
                :value="$route.query.course_date_after"
                :disabled="disabled"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
                @input="setDateFilter('course_date_after', $event)"
            />
            <b-input-group-append>
              <b-form-datepicker
                  :value="$route.query.corse_date_after"
                  :max="max"
                  :disabled="disabled"
                  today-button
                  close-button
                  offset="50px"
                  button-only
                  button-variant="outline-primary"
                  right
                  size="sm"
                  locale="nl"
                  aria-controls="date-to-input"
                  label-help
                  @input="setDateFilter('course_date_after', $event)"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="block">
          <label for="date-to-input">Choose after course date</label>
          <b-input-group>
            <b-form-input
                id="date-to-input"
                :value="$route.query.course_date_before"
                :disabled="disabled"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
                @input="setDateFilter('course_date_after', $event)"
            />
            <b-input-group-append>
              <b-form-datepicker
                  :value="$route.query.course_date_before"
                  :max="max"
                  :disabled="disabled"
                  today-button
                  close-button
                  offset="50px"
                  button-only
                  button-variant="outline-primary"
                  right
                  size="sm"
                  locale="nl"
                  aria-controls="date-to-input"
                  label-help
                  @input="setDateFilter('course_date_before', $event)"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="actions">
        <b-dropdown :disabled="disabled" variant="primary" id="dropdown-1" text="Quick filters" no-flip>
          <b-dropdown-item v-for="(filter, index) in quickFilters"
              :key="index"
              lazy
              @click="selectQuickFilter(filter, {date_before: 'course_date_before', date_after: 'course_date_after'})"
          >
            {{ filter.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="filters-panel">
      <div class="filters">
        <div v-if="showLicenseTypeFilter" class="block license-type">
          <label for="date-from-input">Choose license type</label>
          <b-input-group>
            <b-dropdown
                split
                split-variant="outline-primary"
                variant="primary"
                :disabled="disabled"
                id="license-type-input"
                :text="licenseTypeFilterTitle"
            >
              <b-dropdown-item
                  v-for="(filter, index) in licenseTypesList"
                  :key="index"
                  lazy
                  @click="selectLicenseType(filter)"
                  :active="isLicenseTypeActive(filter.key)"
              >
                {{ filter.label }}
              </b-dropdown-item>
            </b-dropdown>
          </b-input-group>
        </div>

        <div class="block">
          <label for="date-from-input">Choose from date</label>
          <b-input-group>
            <b-form-input
                id="date-from-input"
                :value="$route.query.date_after"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                :disabled="disabled"
                show-decade-nav
                @input="setDateFilter('date_after', $event)"
            />
            <b-input-group-append>
              <b-form-datepicker
                  :value="$route.query.date_after"
                  :max="max"
                  :disabled="disabled"
                  today-button
                  close-button
                  offset="50px"
                  button-only
                  button-variant="outline-primary"
                  right
                  size="sm"
                  locale="nl"
                  aria-controls="date-from-input"
                  label-help
                  @input="setDateFilter('date_after', $event)"
              />
            </b-input-group-append>
          </b-input-group>
        </div>

        <div class="block">
          <label for="date-to-input">Choose to date</label>
          <b-input-group>
            <b-form-input
                id="date-to-input"
                :value="$route.query.date_before"
                :disabled="disabled"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
                @input="setDateFilter('date_before', $event)"
            />
            <b-input-group-append>
              <b-form-datepicker
                  :value="$route.query.date_before"
                  :max="max"
                  :disabled="disabled"
                  today-button
                  close-button
                  offset="50px"
                  button-only
                  button-variant="outline-primary"
                  right
                  size="sm"
                  locale="nl"
                  aria-controls="date-to-input"
                  label-help
                  @input="setDateFilter('date_before', $event)"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="actions">
        <b-dropdown :disabled="disabled" variant="primary" id="dropdown-1" text="Quick filters" no-flip>
          <b-dropdown-item
              v-for="(filter, index) in quickFilters"
              :key="index"
              lazy
              @click="selectQuickFilter(filter, {date_before: 'date_before', date_after: 'date_after'})"
          >
            {{ filter.label }}
          </b-dropdown-item>
        </b-dropdown>
        <b-button
            variant="outline-primary"
            class="ml-1"
            @click="applyFilters"
        >
          Apply
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
import {LicenseTypesEnum} from "@/enums/license-types.const";

export default {
  name: 'Filters',

  props: {
    disabled: { type: Boolean, default: false },
    showCourseFilter: Boolean,
    showLicenseTypeFilter: Boolean,
  },

  data: () => ({
    max: new Date(),
    quickFilters: [
      {key: 'today', label: 'Today'},
      {key: 'yesterday', label: 'Yesterday'},
      {key: 'last7', label: 'Last 7 days'},
      {key: 'last14', label: 'Last 14 days'},
      {key: 'last28', label: 'Last 28 days'},
      {key: 'last30', label: 'Last 30 days'},
      {key: 'week', label: 'This week'},
      {key: 'l_week', label: 'Last week'},
      {key: 'month', label: 'This month'},
      {key: 'l_month', label: 'Last month'},
    ],
    licenseTypesList: [
      {
        key: null,
        label: 'All'
      },
      {
        key: LicenseTypesEnum.AUTO,
        label: 'Auto'
      },
      {
        key: LicenseTypesEnum.MOTOR,
        label: 'Motor'
      },
      {
        key: LicenseTypesEnum.SCOOTER,
        label: 'Scooter'
      },
    ],
  }),

  computed: {
    licenseTypeFilterTitle() {
      let _title = 'All';
      if (this.$route.query.license_type) {
        let _filter = this.licenseTypesList.find(item => item.key === this.$route.query.license_type);
        if (_filter) _title = _filter.label;
      }
      return _title;
    }
  },

  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormDatepicker
  },

  methods: {
    setDateFilter(key, value) {
      let _value = value
      if (!value) _value = undefined
      this.$emit('input', {[key]: _value})
    },

    selectQuickFilter(filter, {date_before, date_after}) {
      let _today = this.$moment().format('YYYY-MM-DD')
      let _yesterday = this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
      let _startWeek = this.$moment().startOf('isoWeek').format('YYYY-MM-DD')
      let _startMonth = this.$moment().startOf('month').format('YYYY-MM-DD')
      let _startLastWeek = this.$moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
      let _endLastWeek = this.$moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
      let _startLastMonth = this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
      let _endLastMonth = this.$moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')

      switch (filter.key) {
        case 'today': {
          this.setQuickFilter({[date_after]:_today, [date_before]: _today})
          break;
        }
        case 'yesterday': {
          this.setQuickFilter({[date_after]:_yesterday, [date_before]: _yesterday})
          break;
        }
        case 'last7': {
          this.setQuickFilter(
            {[date_after]:this.getFirstDate(7), [date_before]: _yesterday})
          break;
        }
        case 'last14': {

          this.setQuickFilter( {[date_after]:this.getFirstDate(14), [date_before]: _yesterday})
          break;
        }
        case 'last28': {
          this.setQuickFilter( {[date_after]:this.getFirstDate(28), [date_before]: _yesterday})
          break;
        }
        case 'last30': {
          this.setQuickFilter( {[date_after]:this.getFirstDate(30), [date_before]: _yesterday})
          break;
        }
        case 'week': {
          this.setQuickFilter( {[date_after]:_startWeek, [date_before]: _today})
          break;
        }
        case 'l_week': {

          this.setQuickFilter({[date_after]:_startLastWeek, [date_before]: _endLastWeek})
          break;
        }
        case 'month': {

          this.setQuickFilter({[date_after]:_startMonth, [date_before]: _today})
          break;
        }
        case 'l_month': {
          this.setQuickFilter( {[date_after]:_startLastMonth, [date_before]: _endLastMonth})
          break;
        }
        default: {
           this.setQuickFilter({[date_after]:_today, [date_before]: _today})
        }
      }
    },

    selectLicenseType(filter) {
      this.$emit('select:filter', {license_type: filter.key})
    },

    getFirstDate(value) {
      return this.$moment().subtract(value, 'days').format('YYYY-MM-DD')
    },

    setQuickFilter(payload) {
      this.$emit('select:filter', payload)
    },

    isLicenseTypeActive(key) {
      if(key === null && !this.$route.query.license_type) return true;
      return this.$route.query.license_type === key;
    },

    applyFilters() {
      if(this.disabled) return;
      this.$emit('apply');
    }
  }
}
</script>

<style lang="scss">
.filters-panel {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 20px 20px 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .block {
      margin-right: 20px;

      @media (max-width: 768px) {
        margin-right: 0;
      }

      &.license-type {
        width: 180px;
        min-width: 180px;

        .dropdown {
          width: 100%;
        }

        .dropdown-toggle {
          width: 40px;
          max-width: 40px;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    @media (max-width: 768px) {
      justify-content: space-between;
      margin-top: 20px;
    }

    .btn {
      text-transform: uppercase;
    }
  }
}
</style>
