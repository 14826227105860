<template>
  <b-card no-body>
    <b-card-header>
      <div class="w-100 d-flex justify-content-between align-items-center">
        <b-card-title v-html="title" />

        <div class="d-flex align-items-center">
          <b-button
              variant="outline-secondary"
              style="width: 40px"
              class="px-0"
              :disabled="loading"
              @click="$emit('click:update')"
          >
            <feather-icon size="16" icon="RefreshCwIcon" />
          </b-button>
        </div>
      </div>
    </b-card-header>

    <b-table
      :items="getList"
      :fields="fields"
      :busy="loading"
      :sort-by.sync="sortBy"
      sortable
      responsive
      :class="tableHashCode"
    >
      <template v-slot:head()="data">
        <span :id="`${tableHashCode}__bookmark-${data.column}`">{{ data.label }}</span>
        <b-tooltip
            v-if="data.field.tooltip"
            placement="auto"
            triggers="hover"
            :target="`${tableHashCode}__bookmark-${data.column}`"
            :title="data.field.tooltip"
            :delay="{ show: 150, hide: 100 }"
        />
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong class="ml-1">Loading...</strong>
        </div>
      </template>
      <slot />
      <template v-if="getTotalRow" v-slot:custom-foot>
        <tr>
          <th v-for="footerItem in fields">
            <span v-html="getTotalRow[footerItem.key]" />
          </th>
        </tr>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BButton, BCard, BCardHeader, BCardTitle, BSpinner, BTable, BTooltip } from 'bootstrap-vue'

export default {
  name: 'MainTable',

  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BSpinner,
    BTooltip
  },

  props: {
    list: Array,
    fields: Array,
    loading: Boolean,
    title: String,
    sortValue: { type: String, default: 'name' }
  },

  data: () => ({
    sortBy: 'name'
  }),

  computed: {
    tableHashCode() {
      return 'table-' + (Math.random() + 1).toString(36).substring(7);
    },

    getList() {
      if (!this.list) return []
      return this.list.filter(item => item.name !== 'Total')
    },

    getTotalRow() {
      if (!this.list) return []
      return this.list.find(item => item.name === 'Total')
    }
  },

  mounted() {
    this.sortBy = this.sortValue
  }
}
</script>